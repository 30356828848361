import { Box, Container } from '@mui/material';
import React from 'react';
import { Survey as SurveyReact } from 'survey-react';
import Auth from '../Components/Auth';

const frameWidth = 'md';

const SurveyFrame = ({ children, survey }) => {
    return (
        <>
            <Box
                sx={{
                    background: survey.logoBackground || '#fff'
                }}
            >
                {/* {user && <LogoutButton />} */}
                <Container
                    maxWidth={frameWidth}
                    sx={{
                        padding: 4,
                        textAlign: 'center',
                    }}
                >
                    {
                        survey.logo ?

                            <img
                                src={survey.logo}
                                style={{
                                    maxWidth: 300,
                                    maxHeight: 90
                                }}
                                alt="Logo"
                            />
                            : `<h1>${survey.title}</h1>`
                    }
                </Container>
            </Box>
            <Container maxWidth={frameWidth} sx={{ mb: 20, mt: 3 }}>
                <Auth anonymous={survey.anonymous}>
                    {children}
                </Auth>
            </Container>
        </>
    );
}

export default SurveyFrame;