import React from 'react';
import Survey from './Components/Survey';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import './assets/defaultV2.min.css';


function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:surveyId" element={<Survey />} />
        <Route path="/:surveyId/:answerId" element={<Survey />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
