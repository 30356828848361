import * as firebase from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
// import { getPerformance } from "firebase/performance";

const firebaseConfig = {
    apiKey: "AIzaSyDz2m2fmHpT6ReThNv4tkNa2wEzwesOLc8",
    authDomain: "management-synergy.firebaseapp.com",
    projectId: "management-synergy",
    storageBucket: "management-synergy.appspot.com",
    messagingSenderId: "1006571838976",
    appId: "1:1006571838976:web:0229dd9761d457069842e5"
};

const app = firebase.initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);
// const perf = getPerformance(app)
// const analytics = getAnalytics();

// useDeviceLanguage(auth);

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    connectFirestoreEmulator(db, 'localhost', 8080);
    connectAuthEmulator(auth, "http://localhost:9099");
}

export default app;
export {
    auth,
    db,
    //  perf,
    //   analytics,
    firebase
}
