import { Box, CircularProgress } from '@mui/material';
import CenterFrame from './CenterFrame';

const Loader = () => {

    return (
        <CenterFrame>
            <Box>
                <CircularProgress color="primary" />
            </Box>
        </CenterFrame>
    );
}

export default Loader;
