import { onAuthStateChanged } from '@firebase/auth';
import { Button, Paper, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { addDoc, collection, doc, getDoc, serverTimestamp, updateDoc } from "firebase/firestore";
import React from 'react';
import { useParams } from 'react-router';
import { useNavigate } from "react-router-dom";
import { Model, StylesManager, Survey as SurveyReact } from 'survey-react';
// import './assets/defaultV2.min.css';
import 'survey-react/modern.min.css';
import { auth, db } from '../config/firebase';
import Loader from '../Views/Loader';
import SurveyFrame from '../Views/SurveyFrame';



StylesManager.applyTheme("defaultV2");
StylesManager.applyTheme("modern");

const Survey = () => {
  const [loaded, setLoaded] = React.useState(false);
  const [survey, setSurvey] = React.useState(null);
  const [answerLoaded, setAnswerLoaded] = React.useState(false);
  const [answer, setAnswer] = React.useState(null);
  const [model, setModel] = React.useState(null);
  const [user, setUser] = React.useState(null);

  const params = useParams();
  const { answerId, surveyId } = params;


  const saveResults = React.useCallback(async (sender) => {
    const results = sender.data;
    const docData = {
      user: {
        uid: (user ? user.uid : null),
        email: (user ? user.email : null),
        displayName: (user ? user.displayName : null),
      },
      timestamp: serverTimestamp(),
      results
    };
    let docRef;
    if (params.answerId) {
      docRef = await updateDoc(doc(db, `surveys/${surveyId}/answers/${params.answerId}`), docData);
    } else {
      docRef = await addDoc(collection(db, `surveys/${surveyId}/answers`), docData);
    }
    return docRef;
  }, [surveyId, user, params]);

  React.useEffect(() => {
    onAuthStateChanged(auth, (usr) => {
      setUser(usr);
    })
  }, []);

  React.useEffect(() => {
    if (!surveyId) {
      return;
    }
    const docRef = doc(db, "surveys", surveyId);
    getDoc(docRef)
      .then((docSnap) => {
        if (docSnap.exists()) {

          setSurvey(docSnap.data());
        } else {
          setSurvey(null);
        }
      })
      .finally(() => {
        setLoaded(true);
      });

  }, [surveyId]);
  React.useEffect(() => {
    if (!answerId || answer) {
      return;
    }
    const docRef = doc(db, `surveys/${surveyId}/answers`, answerId);
    getDoc(docRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          setAnswer(docSnap.data());
        } else {
          setAnswer(null);
        }
      })
      .finally(() => {
        setAnswerLoaded(true);
      });

  }, [answerId, surveyId, answer]);

  React.useEffect(() => {
    if (survey && !model) {
      const tmp = new Model(survey.survey);
      tmp.onComplete.add(saveResults);
      setModel(tmp);
    }
  }, [survey, model, saveResults, answerId]);


  if (!loaded || !survey || !model) {
    return <Loader />;
  }

  const reloadAnswer = () => {
    setAnswerLoaded(false);
    setAnswer(null);
    setModel(null);
  }

  if (survey.inviteOnly) {
    if (!answerId) {
      return <EnterCode survey={survey} reloadAnswer={reloadAnswer} />;
    }
    if (!answerLoaded) {
      return <Loader />;
    }
    if (!answer) {
      return <EnterCode survey={survey} reloadAnswer={reloadAnswer} notice={`Koden du har skrevet inn er ugyldig, prøv på nytt`} />;
    }
    if (answer.results) {
      return (
        <SurveyFrame survey={survey}>
          <Paper sx={{ padding: 3, mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Dette skjemaet er allerede besvart
            </Typography>
            <Typography variant="body1" gutterBottom>
              Dersom du ønsker å svare for en annen ansatt, vennligst skriv inn koden vedkommende har fått på e-post.
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>MERK:</strong> koden som er sendt ut på e-post er unik, det er viktig at du kun benytter koden du selv har fått på e-post.
            </Typography>
            <EnterCodeForm reloadAnswer={reloadAnswer} />
          </Paper>
        </SurveyFrame>
      )
    }
  }

  return (
    <SurveyFrame survey={survey}>
      <Paper sx={{ padding: 3 }}>
        <SurveyReact model={model} />
      </Paper>
    </SurveyFrame>
  );
}

const EnterCode = ({ survey, reloadAnswer, notice = false, skipFrame = false }) => {

  return (
    <SurveyFrame survey={survey}>
      <Paper sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          Skriv inn din kode
        </Typography>
        <Typography variant="body1" gutterBottom>
          For å svare på undersøkelsen, vennligst følg lenken du har mottatt på e-post, eller skriv inn din unike ID i feltet under.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>MERK:</strong> ID-en som er sendt ut på e-post er unik, det er viktig at du ikke bruker andre sin lenke eller kode.
        </Typography>
        {(notice && <Typography variant="body1" gutterBottom color="error">En feil oppstod: {notice}</Typography>)}
        <EnterCodeForm reloadAnswer={reloadAnswer} />
      </Paper>
    </SurveyFrame>
  )
}

const EnterCodeForm = ({ reloadAnswer }) => {
  const [code, setCode] = React.useState("");

  const { surveyId } = useParams();

  const navigate = useNavigate()

  const onSubmit = (e) => {
    e.preventDefault();
    navigate(`/${surveyId}/${code}`);
    reloadAnswer()
    return false;
  }
  return (
    <form onSubmit={onSubmit}>
      <TextField
        name="id"
        variant="outlined"
        placeholder="Skriv inn id her..."
        value={code}
        onChange={(e) => setCode(e.target.value)}
        fullWidth
        autoFocus
      />
      <br /><br />
      <Box sx={{ textAlign: 'right' }}>
        <Button
          variant="contained"
          color="primary"
          type="submit"

        >
          Gå videre
        </Button>
      </Box>
    </form>
  );
}

export default Survey;