import { EmailAuthProvider, onAuthStateChanged } from "firebase/auth";
import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { auth } from "../../config/firebase";

const uiConfig = {
    signInOptions: [{
        provider: EmailAuthProvider.PROVIDER_ID,
        defaultCountry: "NO"
    }],
    callbacks: {
        signInSuccessWithAuthResult: () => false,
        signInFailure: () => false,
        uiShown: () => false,
    }
};

const Auth = ({ children, anonymous = false }) => {
    const [user, setUser] = React.useState(null);

    React.useEffect(() => {
        onAuthStateChanged(auth, (usr) => {
            setUser(usr);
        })
    }, []);

    if (user || anonymous) {
        return children;
    }

    return (
        <>
            {/* <Container maxWidt="sm" sx={{ mt: 3, mb: 3 }}>
                <Paper sx={{ padding: 2 }}>
                    <Typography variant="h4">
                        Velkommen til medarbeiderundersøkelse for Fredrikstad Seafoods
                    </Typography>
                    <Typography variant="body1">
                        Undersøkelsen er anonym, men for å sikre datakvalitet må du logge inn
                    </Typography>
                </Paper>
            </Container> */}
            <StyledFirebaseAuth
                uiConfig={uiConfig}
                firebaseAuth={auth}
            />
        </>
    );
}

export default Auth;
